function UploadController(userProfile, $http, $q, $log, SETTINGS) {
  // maintain a reference to the latest version of the user
  Object.defineProperty(this, 'user', {
    get: () => userProfile.user,
  });

  Object.defineProperty(this, 'hasS3credentials', {
    get: () => !!(
      userProfile.user.cloud &&
      userProfile.user.cloud.s3 &&
      userProfile.user.cloud.s3.credentials &&
      userProfile.user.cloud.s3.credentials.accessID &&
      userProfile.user.cloud.s3.credentials.secret),
  });

  this.buckets = null;
  this.bucketContents = null;
  this.chosenBucket = null;
  this.fetchingBucket = false;
  this.fetchingBucketContents = false;
  this.selectedFiles = [];
  // const checkHasCloudCredentials = (user) => user.cloud && user.cloud.s3 && user.cloud.s3.credentials || false;

  this.$onInit = () => {
    userProfile.getProfileAsync().then(() => {
      this.listBuckets();
    }).catch((err) => $log.error(err));
  };

  this.saveCredentials = (accessID = null, secret = null) => {
    userProfile.saveAsync({
      cloud: {
        s3: {
          credentials: {
            accessID,
            secret,
          }
        },
      },
    }).then(() => this.listBuckets()).catch((err) => $log.debug('error',err));
  };

  let queryCanceler = $q.defer();
  this.listBuckets = () => {
    this.fetchingBucket = false;
    this.chosenBucket = null;

    if(!this.hasS3credentials) {
      this.buckets = null;
      return;
    }

    queryCanceler.resolve();
    queryCanceler = $q.defer();

    this.fetchingBucket = true;
    $http.get(`${SETTINGS.apiEndpoint}aws/bucket`, {timeout: queryCanceler.promise})
    .then( (contents) => {
      this.buckets = contents.data.Buckets;
    }).catch((rejection) => {
      $log.error(rejection);
    }).finally(() => {
      this.fetchingBucket = false;
    });
  };

  this.chooseBucket = (bucketName) => {
    this.chosenBucket = bucketName;

    this.listBucketContents();
  };

  let bucketQueryCanceler = $q.defer();
  this.listPublicContents = () => {
    this.fetchingBucketContents = false;

    // bucketQueryCanceler.resolve();
    // bucketQueryCanceler = $q.defer();

    // this.fetchingBucketContents = true;
    $http.get(`${SETTINGS.apiEndpoint}aws/public-bucket/${this.chosenBucket}`, {timeout: bucketQueryCanceler.promise})
    .then( (contents) => {
      // Add a few properties in order to comply with jobs.upload.component interface
      this.bucketContents = contents.data.Contents.map( (file) => {
        file.name = file.Key;
        file.size = file.Size;
        // Needed for the upload handler to upload the file to the correct bucket
        file.Bucket = this.chosenBucket;
        file.lastModifiedDate = new Date(file.LastModified);
        file.type = 's3';

        return file;
      });
    }).catch((rejection) => {
      $log.error(rejection);
    }).finally(() => {
      this.fetchingBucketContents = false;
    });
  };

  this.listBucketContents = () => {
    this.fetchingBucketContents = false;

    if(!this.chosenBucket) {
      this.bucketContents = null;
      return;
    }

    bucketQueryCanceler.resolve();
    bucketQueryCanceler = $q.defer();

    this.fetchingBucketContents = true;
    $http.get(`${SETTINGS.apiEndpoint}aws/bucket/${this.chosenBucket}`, {timeout: bucketQueryCanceler.promise})
    .then( (contents) => {
      // Add a few properties in order to comply with jobs.upload.component interface
      this.bucketContents = contents.data.Contents.map( (file) => {
        file.name = file.Key;
        file.size = file.Size;
        // Needed for the upload handler to upload the file to the correct bucket
        file.Bucket = this.chosenBucket;
        file.lastModifiedDate = new Date(file.LastModified);
        file.type = 's3';

        return file;
      });
    }).catch((rejection) => {
      $log.error(rejection);
    }).finally(() => {
      this.fetchingBucketContents = false;
    });
  };

  this.toggleFileSelected = (file) => {
    const index = this.selectedFiles.indexOf(file);

    if(index > -1) {
      this.selectedFiles.splice(index, 1);
    } else {
      this.selectedFiles.push(file);
    }
  };
}

angular.module('sq.jobs.upload.cloud.component', ['ngFileUpload', 'sq.config'])
.component('sqCloudUpload', {
  bindings: {
    onSelected: '&',
    onCancelled: '&',
  }, // isolate scope
  templateUrl: 'jobs/upload/jobs.upload.cloud.tpl.html',
  controller: UploadController,
});